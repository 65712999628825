
import React from 'react';
import { Link } from 'react-router-dom';
import './Registry.css';

function TeamsDirectory() {
  
  return (
    <div className='container'>
      <div className="teams-title"> </div>
      <h2>Teams Directory </h2>
      <p>The Trust Broker Africa (TBA) maintains the African database of CSIRTs (also known as CERTs) and security teams. 
        The teams in the TBA database are known as listed teams. 
        A team's request to become listed is submitted to the accredited 
        teams community (see
          <Link to="/join/listed-team" className="link"> How to become listed </Link>) for further information.</p>
        <p>Accredited and certified teams are a subset of the listed teams and fulfill 
           the requirements of the formal TBA accreditation (see
            <Link to="/join/accredited-team" className="link"> How to become accredited </Link>) for further Information.</p>
        <p>Below you find the publicly available data for all listed teams - these data are maintained only on 
           request or when errors are observed or reported, and are thus provided best effort only.</p>
        <p>Additionally, the publicly available data for all accredited and certified teams are offered. 
          These data are being maintained. The data on offer here are a small subset of the data available only to the accredited teams themselves.</p>
      
      <div className="links-section">
        <div className="team-info">
          <h3 className="links-title">Team Database</h3>
          <p className="description">Searchable Team Database</p>
        </div>

        <div className="team-info">
          <h3 className="links-title">Team Information as JSON data</h3>
          <div className="team-item">
            <span>All Teams Listed by the TBA Service</span>
            <a href="/path/to/teams.json" className="team-link">teams.json</a>
            <a href="/path/to/json/schema" className="team-link">JSON Schema</a>
          </div>
        </div>

        <div className="team-info">
          <h3 className="links-title">Team Information Lists</h3>
          <div className="team-item">
            <span>Team information for listed teams</span>
            <Link to="/csirlist" className="team-link">
              from A-Z
            </Link> {/* Use Link instead of a tag for internal navigation */}
            <Link to="/teamlist" className="team-link">By country</Link>
          </div>

          <div className="team-item">
            <span>Team information for accredited teams</span>
            <Link to="/accreditedteamslist" className="team-link">from A-Z</Link>
            <Link to="/accreditedlist" className="team-link">By country</Link>
          </div>

          <div className="team-item">
            <span>Team information for certified teams</span>
            <Link to="/certifiedteamslist" className="team-link">from A-Z</Link>
            <Link to="/certifiedlist" className="team-link">By country</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamsDirectory;

