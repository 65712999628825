import React from 'react';
import './App.css';


const MoreNetCsirt=() => {
  return (
    <div className="container">
      <h2>MoRENet CSIRT (MZ) </h2>
      <h3>CSIRT of Mozambique Research and Education Network</h3>
      <p><strong>Listed</strong> since 28 Nov 2023</p>
      <p className="info-text">This information is provided without guarantee or pro-active maintenance.</p>

      <h3>Fields describing the team</h3>

      <div className="section">
        <h4>Team Details</h4>
        <div className="teams-details">
          <div className="teams-detail">
            <strong>Official Name</strong>
            <p>CSIRT of Mozambique Research and Education Network</p>
          </div>
          <div className="teams-detail">
            <strong>Short Name</strong>
            <p>MoRENet CSIRT</p>
          </div>
          <div className="teams-detail">
            <strong>Country</strong>
            <p> 
            <img src="/assets/images/mozambique.png" alt="Mozambique Flag" className="flag-icon" /> Mozambique</p>
          </div>
          <div className="teams-detail">
            <strong>Established</strong>
            <p>03 Nov 2018</p>
          </div>
          <div className="teams-detail">
            <strong>Host Organisation</strong>
            <p>-</p>
          </div>
        </div>
      </div>

      <h3>Constituency</h3>
      <div className="constituency">
        <div className="constituency-detail">
          <strong>Constituency Type</strong>
          <p>Research & Education</p>
        </div>
        <div className="constituency-detail">
          <strong>Country of Constituency</strong>
          <p>Mozambique</p>
        </div>
        <div className="constituency-detail">
          <strong>ASNs, Domains, IP ranges</strong>
          <p>31960</p>
           <p>327700</p>
            <p>327951</p>

            <p>41.94.0.0/16</p>
            <p>196.3.96.0/21</p>
            <p>196.13.101.0/24</p>
        </div>
        <div className="constituency-detail description">
          <strong>Description of Formal Constituency</strong>
          <p>Higher Education Institutions of Mozambique</p>
          <p>Technical and Vocational Education and Training Institutions of Mozambique</p>
           <p>Research Institutions of Mozambique</p>
        </div>
      </div>

      <h3>Team Contact Information</h3>
      <div className="contact-infos">
        <div className="contact-details">
          <strong>Main Number</strong>
          <p>+258 84 20 69 850</p>
          
        </div>
        <div className="contact-details">
          <strong>Emergency Number</strong>
          <p>+258 84 20 69 850</p>
        </div>
        <div className="contact-details">
          <strong>Fax Number</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Email</strong>
          <p>incidents@ke-cirt.go.ke</p>
        </div>
        <div className="contact-details">
          <strong>Other Contact</strong>
          <p>Twitter: CA_Kenya,</p>
          <p>Facebook: CAOKenya</p>
        </div>
        <div className="contact-details">
          <strong>Postal Address</strong>
          <p>MaputoEstrada Naciona no 1, Km 60
        <p>District of Manhica</p>
         <p></p>Maputo Province</p>
        </div>
        <div className="contact-details">
          <strong>Automated Reporting E-Mail</strong>
          <p>security@morenet.ac.mz</p>
        </div>
        <div className="contact-details">
          <strong>Autom. Rep. Supported Formats</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Business Hours</strong>
          <p>24/7</p>
          
        </div>
        <div className="contact-details">
          <strong>Timezone</strong>
          <p>Africa/Maputo</p>
        </div>
      </div>

      <h3>Cryptography</h3>
      <div className="cryptography">
      <p>Type: RSA/2048    Expires: 2024-08-22</p>
        <p>Fpr: 3FFF FDEB 9D7C 5237 B1F0 6D93 A956 C331 E311 7CD3</p>
        <p>Sub: RSA/2048  Usage: Encrypt  Expires: 2024-08-22</p>
        <p>UID: NMoRENet-CSIRT &lt;cert@morenet.ac.mz&gt;</p>
        
</div>
        <div className="section">
        
        <h3>Memberships</h3>
      <div className="memberships-infos"></div>
        <div className="memberships-details">
          <strong>AfricaCERT / OIC-CERT</strong>
          <p>Full Member since </p> 
        </div>
        <div className="memberships-details">
          <strong>TF-CSIRT / TI	</strong>
          <p>TI Listed since</p> 
        </div>

        <div className="section">
        <h3>Classification</h3>
        <div className="classifications-infos"></div>
        <div className="classification-details">
          <strong>Current State	</strong>
          <p>Listed since 28 Nov 2023</p>
          </div>

         <div className="classification-details">
          <strong> Entry Date	</strong>
          <p>17 Nov 2022</p>
          </div>

          <div className="section">

        <h3>History</h3>
        <div className="history-infos"></div>
        <div className="historys-details">
          <strong>Date	</strong>
          <p> 28 Nov 2023</p>
          </div>

         <div className="historys-details">
          <strong>Describtion</strong>
          <p>MoRENet CSIRT (MZ) is now a listed team</p>
          </div> 
        </div>
        </div>
        </div>
      </div>
  );
};

export default  MoreNetCsirt;
