import React from 'react';
import './App.css';


const KENETCert=() => {
  return (
    <div className="container">
      <h2>KENET-CERT (KE)</h2>
      <h3>KENYA EDUCATION NETWORK CYBERSECURITY EMERGENCY RESPONSE TEAM</h3>
      <p><strong>Listed</strong> since 08 April 2024</p>
      <p className="info-text">This information is provided without guarantee or pro-active maintenance.</p>

      <h3>Fields describing the team</h3>

      <div className="section">
        <h4>Team Details</h4>
        <div className="teams-details">
          <div className="teams-detail">
            <strong>Official Name</strong>
            <p>KENYA EDUCATION NETWORK CYBERSECURITY EMERGENCY RESPONSE TEAM</p>
          </div>
          <div className="teams-detail">
            <strong>Short Name</strong>
            <p>KENET-CERT (KE)</p>
          </div>
          <div className="teams-detail">
            <strong>Country</strong>
            <p> 
            <img src="/assets/images/Kenya.png" alt="Kenya Flag" className="flag-icon" /> Kenya
            </p>
          </div>
          <div className="teams-detail">
            <strong>Established</strong>
            <p>01 Jan 2015</p>
          </div>
          <div className="teams-detail">
            <strong>Host Organisation</strong>
            <p>KENYA EDUCATION NETWORK</p>
          </div>
        </div>
      </div>

      <h3>Constituency</h3>
      <div className="constituency">
        <div className="constituency-detail">
          <strong>Constituency Type</strong>
          <p>Research & Education</p>
        </div>
        <div className="constituency-detail">
          <strong>Country of Constituency</strong>
          <p>Kenya</p>
        </div>
        <div className="constituency-detail">
          <strong>ASNs, Domains, IP ranges</strong>
          <p>36914</p>
        </div>
        <div className="constituency-detail description">
          <strong>Description of Formal Constituency</strong>
          <p>Our constituency is the Research and Education Network of Kenya.</p>
          <p>KENET-CERT coordination center promotes awareness on cybersecurity incidences as well as coordinates and assists member institutions in responding effectively to cyber security threats and incidences. KENET-CERT works closely with Kenya's National CIRT coordination center (CIRT/CC) as a sector CIRT for the academic institutions.</p>
        </div>
      </div>

      <h3>Team Contact Information</h3>
      <div className="contact-infos">
        <div className="contact-details">
          <strong>Main Number</strong>
          <p>+254 7 32150000</p>
          
        </div>
        <div className="contact-details">
          <strong>Emergency Number</strong>
          <p>+254 7 03044000</p>
        </div>
        <div className="contact-details">
          <strong>Fax Number</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Email</strong>
          <p>cert@kenet.or.ke</p>
        </div>
        <div className="contact-details">
          <strong>Other Contact</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Postal Address</strong>
          <p>KENYA EDUCATION NETWORK</p>
          <p>JOMO KENYATTA MEMORIAL LIBRARY</p>
          <p>UNIVERSITY OF NAIROBI - MAIN CAMPUS</p>
          <p>P.O Box 30244-00100 Nairobi</p>
        </div>
        <div className="contact-details">
          <strong>Automated Reporting E-Mail</strong>
          <p>incident@gmcsirt.gm</p>
        </div>
        <div className="contact-details">
          <strong>Autom. Rep. Supported Formats</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Business Hours</strong>
          <p>08:00 to 15:00 EAT</p>
          
        </div>
        <div className="contact-details">
          <strong>Timezone</strong>
          <p>Africa/Nairobi</p>
        </div>
      </div>

      <h3>Cryptography</h3>
      <div className="cryptography">
        
</div>
        <div className="section">
        
        <h3>Memberships</h3>
      <div className="memberships-infos"></div>
        <div className="memberships-details">
          <strong>AfricaCERT / OIC-CERT</strong>
          <p>Full Member since</p> 
        </div>

        <div className="section">
        <h3>Classification</h3>
        <div className="classifications-infos"></div>
        <div className="classification-details">
          <strong>Current State	</strong>
          <p>Listed since 08 April 2024</p>
          </div>

         <div className="classification-details">
          <strong> Entry Date	</strong>
          <p>18 Nov 2022</p>
          </div>

          <div className="section">

        <h3>History</h3>
        <div className="history-infos"></div>
        <div className="historys-details">
          <strong>Date	</strong>
          <p> 08 April 2024</p>
          </div>

         <div className="historys-details">
          <strong>Describtion</strong>
          <p>KENET-CERT (KE) is now a listed team</p>
          </div> 
        </div>
        </div>
        </div>
      </div>
  );
};

export default KENETCert;
