import React from 'react';
import './App.css'; 

const TeamInfo = () => {
  return (
    <div className="team-info-container">
      <h1>bjCSIRT (BJ)</h1>
      <h2>Benin Incident Response Team</h2>

      <div className="team-status">
        <strong>Status:</strong> Listed since 15 Dec 2023
      </div>
      <p className="disclaimer">
        This information is provided without guarantee or pro-active maintenance.
      </p>

      <div className="team-details">
        <h3>Fields describing the team</h3>
        <table>
          <tbody>
            <tr>
              <td><strong>Official Name:</strong></td>
              <td>Benin Incident Response Team</td>
            </tr>
            <tr>
              <td><strong>Short Name:</strong></td>
              <td>bjCSIRT (BJ)</td>
            </tr>
            <tr>
              <td><strong>Country:</strong></td>
              <td>
                <img src="/assets/images/Benin.jpeg" alt="Benin Flag" className="flag-icon" /> Benin
              </td>
            </tr>
            <tr>
              <td><strong>Established:</strong></td>
              <td>01 Sep 2017</td>
            </tr>
            <tr>
              <td><strong>Host Organisation:</strong></td>
              <td>Agence Nationale de la Securite des Systemes de Information (ANSSI)</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="asn-info">
        <h3>ASNs, Domains, IP ranges</h3>
        <ul>
          <li>28683</li>
          <li>37090</li>
          <li>37136</li>
          <li>37292</li>
          <li>37424</li>
          <li>328098</li>
          <li>328138</li>
          <li>*.bj</li>
        </ul>
      </div>

      <div className="constituency-description">
        <h3>Description of Formal Constituency</h3>
        <p>
          The government’s IT infrastructure, the state agencies, the institutions
          and operators of vital importance such as banks, telecom providers, Internet
          Service Providers, and the national cyberspace.
        </p>
      </div>

      <div className="team-contact-info">
        <h3>Team Contact Information</h3>
        <table>
          <tbody>
            <tr>
              <td><strong>Main Number:</strong></td>
              <td>+229 21 36 87 23</td>
            </tr>
            <tr>
              <td><strong>Emergency Number:</strong></td>
              <td>+229 97 66 87 27</td>
            </tr>
            <tr>
              <td><strong>Fax Number:</strong></td>
              <td>-</td>
            </tr>
            <tr>
              <td><strong>Email:</strong></td>
              <td><a href="mailto:csirt.contact@anssi.bj">csirt.contact@anssi.bj</a></td>
            </tr>
            <tr>
              <td><strong>Other contact:</strong></td>
              <td>-</td>
            </tr>
            <tr>
              <td><strong>Postal Address:</strong></td>
              <td>Immeuble Fawaz, Rue des Cheminots, Zongo 01 BP 2028 Cotonou</td>
            </tr>
            <tr>
              <td><strong>Automated Reporting E-Mail:</strong></td>
              <td>-</td>
            </tr>
            <tr>
              <td><strong>Autom. Rep. Supported Formats:</strong></td>
              <td>-</td>
            </tr>
            <tr>
              <td><strong>Business Hours:</strong></td>
              <td>08:00 to 12:30 and 14:00 to 20:30 <br /> Monday to Friday</td>
            </tr>
            <tr>
              <td><strong>Timezone:</strong></td>
              <td>Africa/Porto-Novo</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Cryptography Section */}
      <div className="cryptography-infos">
        <h3>Cryptography</h3>
        <pre>
        <p>Type: RSA/2048 Expires: never</p>
        <p>Fpr: 215A DA01 AB0A DC00 9EEF 768B 2725 D162 A021 CE57</p>
        <p>Sub: RSA/2048 Usage: Encrypt</p>
        <p>UID: BJCSIRT (National CSIRT of Benin Republic) &lt;contact@csirt.gouv.bj&gt;</p>
        </pre>
      </div>

      {/* Memberships Section */}
      <div className="memberships-info">
        <h3>Memberships</h3>
        <table>
          <tbody>
            <tr>
              <td>AfricaCERT / OIC-CERT</td>
              <td>Full Member since</td>
            </tr>
            <tr>
              <td>FIRST</td>
              <td>Full Member since 28 Jan 2021</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Classification Section */}
      <div className="classification-info">
        <h3>Classification</h3>
        <table>
          <tbody>
            <tr>
              <td><strong>Current State:</strong></td>
              <td>Listed since 15 Dec 2023</td>
            </tr>
            <tr>
              <td><strong>Entry Date:</strong></td>
              <td>17 Nov 2022</td>
            </tr>
            <tr>
              <td><strong>Date of Accreditation:</strong></td>
              <td>-</td>
            </tr>
            <tr>
              <td><strong>Date of Certification:</strong></td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* History Section */}
      <div className="history-info">
        <h3>History</h3>
        <table>
          <tbody>
            <tr>
              <td>15 Dec 2023</td>
              <td>bjCSIRT (BJ) is now a listed team</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamInfo;
