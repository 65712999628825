import React from 'react';
import './Join.css'; // Ensure you import the CSS file
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function AccreditedTeam() {
  const { t, i18n } = useTranslation();
  

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="accredited-team-page">
      <h1 className="title">{t('Joining TrustBroker Africa as')} <span className="highlight">{t('TBA Accredited team')} </span></h1>
      <p className="describe">
        {t('Only already')} <Link to="/join/listed-team" className="link">"{t('listedd')}"</Link> {t('teams can become accredited')}. 
         {t('gain accreditation')}. <br />  
      </p>
      <p>{t('The accreditation process')}.
      </p>
      <div className="image-placeholder">
        <img src="/assets/images/tba_accrediting.png" alt="Placeholder for Accredited Team" />
      </div>
      <div className="steps-section">
        <h2> {t('steps')}:</h2>
        <ol>
          <li>{t('If a team is not yet listed')} <Link to="/join/listed-team" className="link">{t('apply for this first')}.</Link> </li>
          <li>{t('To apply for accreditation the team just needs to send a simple request to the')} <Link to="/support" className="link">{t('TBA team')}.</Link></li>
          <li> {t('sending a signature page')}.</li>
          <li>{t('accreditation candidate')}.</li>
          <li>{t('Starting from the recognition')}  
          <Link to="/join/accredited-team" className="link"> {t('TBA Self-Service')} </Link> 
           {t(' outlining the required statements and information')}.</li>
          <li>{t('verification')}. </li>
          <li>{t('verified')}. </li>
          <li>{t('any stage')}.</li>

        </ol>
      </div>
      <div className="related-materials">
        <h2>Related Materials</h2>
        <p className="related-description">
        {t('Materials of interest for this process are')}:
        </p>
        <ul>
          <li>{t('none so far')}
          </li>
          </ul>
      </div>
    </div>
  );
}

export default AccreditedTeam;
