import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';


const teamsData = [
  {
    country: 'Benin',
    teams: [
      { name: 'bjCSIRT (BJ)', date: '15 Dec 2023', link: '/teaminfo' }
    ]
  },
  {
    country: 'Botswana',
    teams: [
      { name: 'Botswana-CSIRT (BW)', date: '23 Jan 2024', link: '/teamdetails' }
    ]
  },
  {
    country: 'Gambia',
    teams: [
      { name: 'gmCSIRT (GM)', date: '28 Nov 2023', link: '/gmcsirt' }
    ]
  },
  {
    country: 'Kenya',
    teams: [
      { name: 'KENET-CERT (KE)', date: '08 Apr 2024', link: '/kenetcert' },
      { name: 'National KE-CIRT/CC (KE)', date: '18 Jan 2024', link: '/kecirt' }
    ]
  },
  {
    country: 'Mozambique',
    teams: [
      { name: 'MoRENet CSIRT (MZ)', date: '28 Nov 2023',link: '/morenetcsirt' }
    ]
  },
  {
    country: 'South Africa',
    teams: [
        {name: 'SA NREN CSIRT (ZA)', date: '28 Nov 2023', link:'/sacsirt' },
        {name: 'UCT CSIRT (ZA)', date: '28 Nov 2023', link:'/uctcsirt' }
    ]
  },
  {
    country: 'Togo',
    teams: [
      { name: 'CERT.tg (TG)', date: '09 July 2024',link:'/tgcert' }
    ]
  },

  {
    country: 'Uganda',
    teams: [
      { name: 'RENU-CERT (UG)', date: '16 Jan 2024',link:'/renucert' }
    ]
  }

];

const TeamList = () => {
  return (
    <div className="container">
      <p>The following list contains all <strong>"listed" </strong>teams. <strong>"Accredited" </strong>teams are - by definition - also "listed" teams, and are therefore contained in this list for your convenience.</p>
      <p>This list contains the same information as the <strong> alphabetical list</strong>, but is sorted by countries instead of team names.</p>
      <ul>
        {teamsData.map((teamGroup, index) => (
          <li key={index}>
            <h3>{teamGroup.country}</h3>
            <ul>
              {teamGroup.teams.map((team, i) => (
                <li key={i}>
                  <strong>
                  <Link to={team.link}>{team.name}</Link>
                  </strong> - Listed (since {team.date})
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>



    </div>
  );
};

export default TeamList;




