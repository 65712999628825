import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import './About.css';
import { useTranslation } from 'react-i18next';

function Resources() {
  const { t, i18n } = useTranslation();
  

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="container">
      <h1>{t('resource')}</h1>

      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Type</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a href="/" target="_blank" rel="noopener noreferrer">
               {t('CSIRT')}.
              </a>
            </td>
            <td>{t('policies')}</td>
            <td>2024</td>
          </tr>
          <tr>
            <td>
              <a href="/ngCERT role in Cybersecurity EDITED.pdf" target="_blank" rel="noopener noreferrer">
                {t('NGCERT')}.
              </a>
            </td>
            <td>{t('policies')}</td>
            <td>2024</td>
          </tr>
          <tr>
            <td>
              <a href="/TRANSIT I - Organisational Module_Mirek.pptx" target="_blank" rel="noopener noreferrer">
                {t('TRANSIT')} 
              </a>
            </td>
            <td>{t('policies')}</td>
            <td>2024</td>
          </tr>
          <tr>
            <td>
              <a href="/TRANSITS I - Operational Module.pdf" target="_blank" rel="noopener noreferrer">
              {t('TRANSITS')}
              </a>
            </td>
            <td>{t('policies')}</td>
            <td>2024</td>
          </tr>
          <tr>
            {/* <td>
              <a href="/policies.pdf" target="_blank" rel="noopener noreferrer">
                The TrustBroker Africa Service forms the trusted backbone of infrastructure services and serves as a clearinghouse for all security and incident response teams.
              </a>
            </td> */}
            {/* <td>Policies</td>
            <td>2024</td> */}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Resources;
