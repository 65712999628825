import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';



const AccreditedList = () => {
  return (
    <div className="teamlist">
      <p>
        The following list contains all <strong>"certified"</strong> teams. 
        This list contains the same information as the <Link to="/teamlist"> alphabetical list </Link>, but is sorted by team names.
      </p>
      <p>
        All <strong>"listed"</strong> teams are contained in the <Link to="/csirlist" > full list </Link>.
      </p>
    </div>
  );
};

export default AccreditedList;
