import React from 'react';
import { Link } from 'react-router-dom';
import "./App.css";

const teamData = [
  { name: "bjCSIRT (BJ)", country: "Benin", listedSince: "15 Dec 2023", link: '/teaminfo' },
  { name: "Botswana-CSIRT (BW)", country: "Botswana", listedSince: "23 Jan 2024", link: '/teamdetails' },
  { name: "CERT.tg (TG)", country: "Togo", listedSince: "09 Jul 2024", link: '/tgcert' },
  { name: "gmCSIRT (GM)", country: "Gambia", listedSince: "28 Nov 2023", link: '/gmcsirt' },
  { name: "KENET-CERT (KE)", country: "Kenya", listedSince: "08 Apr 2024", link: '/kenetcert' },
  { name: "MoRENet CSIRT (MZ)", country: "Mozambique", listedSince: "28 Nov 2023", link: '/morenetcsirt' },
  { name: "National KE-CIRT/CC (KE)", country: "Kenya", listedSince: "18 Jan 2024", link: '/kecirt' },
  { name: "RENU-CERT (UG)", country: "Uganda", listedSince: "16 Jan 2024", link: '/renucert' },
  { name: "SA NREN CSIRT (ZA)", country: "South Africa", listedSince: "28 Nov 2023", link: '/sacsirt' },
  { name: "UCT CSIRT (ZA)", country: "South Africa", listedSince: "28 Nov 2023", link: '/uctcsirt' },
];

const CSIRList = () => {
  return (
    <div>
      {/* <h2>CSIR Teams</h2> */}
      <p>
        The following list contains all "listed" teams. 
        <strong>"Accredited"</strong> teams are - by definition - also "listed" teams, and are therefore contained in this list for your convenience.
        This list contains the same information as the <a href='./teamlist'>list by countries</a>, but is sorted by team names.
      </p>
      <table>
        <thead>
          <tr>
            <th>Team Name</th>
            <th>Country</th>
            <th>Listed Since</th>
          </tr>
        </thead>
        <tbody>
          {teamData.map((team, index) => (
            <tr key={index}>
              <td>
                <Link to={team.link}>{team.name}</Link>
                
              </td>
              <td>{team.country}</td>
              <td>{team.listedSince}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CSIRList;
