import React from 'react';
import './App.css';


const UCTCsirt=() => {
  return (
    <div className="container">
      <h2>UCT CSIRT (ZA) </h2>
      <h3>University of Cape Town Computer Security Incident Response Team</h3>
      <p><strong>Listed</strong> since 28 Nov 2023</p>
      <p className="info-text">This information is provided without guarantee or pro-active maintenance.</p>

      <h3>Fields describing the team</h3>

      <div className="section">
        <h4>Team Details</h4>
        <div className="teams-details">
          <div className="teams-detail">
            <strong>Official Name</strong>
            <p>University of Cape Town </p>
              <p>Computer Security Incident</p> 
                <p>Response Team</p>
          </div>
          <div className="teams-detail">
            <strong>Short Name</strong>
            <p>UCT CSIRT (ZA)</p>
          </div>
          <div className="teams-detail">
            <strong>Country</strong>
            <p> 
            <img src="/assets/images/southafrica.jpg" alt="South Africa Flag" className="flag-icon" />South Africa</p>
          </div>
          <div className="teams-detail">
            <strong>Established</strong>
            <p>19 Feb 2019</p>
          </div>
          <div className="teams-detail">
            <strong>Host Organisation</strong>
            <p>19 Feb 2019</p>
          </div>
        </div>
      </div>

      <h3>Constituency</h3>
      <div className="constituency">
        <div className="constituency-detail">
          <strong>Constituency Type</strong>
          <p>Research & Education</p>
        </div>
        <div className="constituency-detail">
          <strong>Country of Constituency</strong>
          <p>South Africa</p>
        </div>
        <div className="constituency-detail">
          <strong>ASNs, Domains, IP ranges</strong>
          <p>*.uct.ac.za</p>
        </div>
        <div className="constituency-detail description">
          <strong>Description of Formal Constituency</strong>
          <p>UCT, including academic and administrative staff.</p>
          <p>UCT contractors (fixed term and 6 months or less),</p>
           <p>UCT students (limited scope of services).</p>
           <p>UCT alumni (limited scope of services)</p>
        </div>
      </div>

      <h3>Team Contact Information</h3>
      <div className="contact-infos">
        <div className="contact-details">
          <strong>Main Number</strong>
          <p>+27 21 650 4500 IT HELPDESK</p>
          
        </div>
        <div className="contact-details">
          <strong>Emergency Number</strong>
          <p>+27 21 650 4500 IT HELPDESK</p>
        </div>
        <div className="contact-details">
          <strong>Fax Number</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Email</strong>
          <p>uctcsirt@uct.ac.za</p>
        </div>
        <div className="contact-details">
          <strong>Other Contact</strong>
          <p>TAlternative to reach the team through I</p>
          <p>T HELPDESK: Tel: 021 650-4500 </p>
            <p>icts-helpdesk@uct.ac.za</p>
        </div>
        <div className="contact-details">
          <strong>Postal Address</strong>
          <p>UCT CSIRT,</p>
        <p>on Main Building, 7 Main Road, </p>
         <p>MMowbray, 7701, Cape Town</p>
        </div>
        <div className="contact-details">
          <strong>Automated Reporting E-Mail</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Autom. Rep. Supported Formats</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Business Hours</strong>
          <p>08:00 to 16:30 Monday to Friday	</p>
          
        </div>
        <div className="contact-details">
          <strong>Timezone</strong>
          <p>Africa/Johannesburg</p>
        </div>
      </div>

      <h3>Cryptography</h3>
      <div className="cryptography">
      <p>Type: RSA/4096    Expires: 2029-03-20

    </p>
        <p>Fpr: 7F54 2EBB F918 17FF 1B1B 209A AD3E B272 BF6B 73C8</p>
        <p>Sub: RSA/4096  Usage: Encrypt  Expires: 2029-03-20</p>
        <p>UID:  UCT CSIRT &lt;uctcsirt@uct.ac.za&gt;</p>
        
</div>
        <div className="section">
        
        <h3>Memberships</h3>
      <div className="memberships-infos"></div>
       
        <div className="memberships-details">
          <strong>FIRST</strong>
          <p>Full Member since 23 May 2019</p> 
        </div>
        <div className="memberships-details">
          <strong>AfricaCERT / OIC-CERT</strong>
          <p>Full Member since </p> 
        </div>
        <div className="section">
        <h3>Classification</h3>
        <div className="classifications-infos"></div>
        <div className="classification-details">
          <strong>Current State	</strong>
          <p>Listed since 28 Nov 2023</p>
          </div>

         <div className="classification-details">
          <strong> Entry Date	</strong>
          <p>17 Nov 2022</p>
          </div>

          <div className="section">

        <h3>History</h3>
        <div className="history-infos"></div>
        <div className="historys-details">
          <strong>Date	</strong>
          <p> 28 Nov 2023</p>
          </div>

         <div className="historys-details">
          <strong>Describtion</strong>
          <p>UCT CSIRT (ZA) is now a listed team</p>
          </div> 
        </div>
        </div>
        </div>
      </div>
  );
};

export default  UCTCsirt;
