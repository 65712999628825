import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import './Support.css'; 
import { useTranslation } from 'react-i18next';

function Support() {
  const { t, i18n } = useTranslation();
  

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="support-container">
      
      <div className="confidentiality-email">
      <h2>{t('Confidentiality')}</h2>
      <p>
        {t('confidential data')}
      </p>

      <div className="pgp-box">
        <pre>
          User ID: TrustBroker Africa (TBA) ** TICKET SYSTEM ** {"\n"}
          tba@trustbroker.africa {"\n"}
          Key ID: 966D5533 {"\n"}
          Key type: RSA {"\n"}
          Key size: 4096 {"\n"}
          Fingerprint: 5A70 453F 2E00 FF3C 36B7 7CBB 014F 2864 966D 5533
        </pre>
      </div>

      <p>
        {t('authenticity')}
      </p>

      <div className="pgp-box">
        <pre>
          User ID: TrustBroker Africa (TBA) ** MASTER SIGNING ** {"\n"}
          Key ID: C1AEC76D {"\n"}
          Key type: RSA {"\n"}
          Key size: 4096 {"\n"}
          Fingerprint: 5474 A6FE B846 5ACB EC5C 3A75 9510 5F46 C1AE C76D
        </pre>
      </div>
    </div>
      <div className="contact-info">
        <div className="contact-item">
          <div className="image-overlay">
            <img src="assets/images/address.png" alt="Address" className="contact-image" />
            <div className="overlay">
             
              <ul>
              <li> TrustBroker Africa Service </li>
               <li> C/o WACREN </li>
               <li> VCG Office Complex, IPS Road </li>
               <li> P O Box LG 1279, Accra, Ghana </li>
               </ul>
            </div>
          </div>
        </div>
        
        <div className="contact-item">
          <div className="image-overlay">
            <img src="assets/images/Email.jpg" alt="" className="contact-image" />
            <div className="overlay">
              <ul>
                <li>
              {' '}
            <a href ="mailto:tba@trustbroker.africa" target="_blank" rel="noopener noreferrer">
            tba@trustbroker.africa
            </a>{' '} </li>
            </ul>
            </div>
          </div>
        </div>
      </div>
      

      <div className="service-governance">
      <h2>{t('Service Governance')} </h2>
      <p>
        {t('The TrustBroker Africa Service is operated by')} <a href="https://www.wacren.net" target="_blank" rel="noopener noreferrer">WACREN</a>, 
        {t('partnership')} <a href="https://www.ubuntunet.net" target="_blank" rel="noopener noreferrer"> Ubuntunet Alliance </a> 
        {t('and')} <a href="https://www.asrenorg.net" target="_blank" rel="noopener noreferrer">ASREN</a> {t('as part of the')} 
        <a href="https://africaconnect3.net" target="_blank" rel="noopener noreferrer"> AfricaConnect3 project</a> {t('co-funded ')}.
      </p>
      <p>
        {t('The TBA Steering Committee')}
      </p>
      <p>{t('tasks of the TBA Steering Committee')}</p>
      <ul>
        <li>{t('Making decisions')}</li>
        <li>{t('Reviewing the performance')}</li>
      </ul>
      <div className="service-manager">
      <h3>{t('Service Manager')}</h3>
      <p>
        {t('The service manager')} <a href="https://www.linkedin.com/in/omooaiya/" target="_blank" rel="noopener noreferrer">Omo Oaiya</a>. 
        {t('special requests')} 
        <a href="/Omo keys.odt" target="_blank" rel="noopener noreferrer"> {t('his PGP key')}</a> {t('to encrypt any email sent to him')}
      </p>
    </div>
    </div>

      
    </div>
  );
}

export default Support;



