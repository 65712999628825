import React from 'react';
import './App.css'; // Add your CSS styles here for layout and formatting

function TeamDetails() {
  return (
    <div className="team-details">
      <h2>Botswana National CSIRT</h2>
      <p><strong>Listed</strong><br />since 23 Jan 2024</p>
      <p>This information is provided without guarantee or pro-active maintenance.</p>

      <div className="fields-section">
        <h4>Fields describing the team</h4>

        <div className="team-details-section">
          <h5>Team Details</h5>
          <table>
            <tbody>
              <tr>
                <td><strong>Official Name</strong></td>
                <td>Botswana National CSIRT</td>
              </tr>
              <tr>
                <td><strong>Short Name</strong></td>
                <td>Botswana-CSIRT (BW)</td>
              </tr>
              <tr>
                <td><strong>Country</strong></td>
                <td>
                <img src="/assets/images/botwana.png" alt="Botwana Flag" className="flag-icon" />Botswana
                </td>
              </tr>
              <tr>
                <td><strong>Established</strong></td>
                <td>01 Dec 2020</td>
              </tr>
              <tr>
                <td><strong>Host Organisation</strong></td>
                <td>Botswana Communications Regulatory Authority</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="constituency-section">
          <h5>Constituency</h5>
          <table>
            <tbody>
              <tr>
                <td><strong>Constituency Type</strong></td>
                <td>CIIP, Government, ISP Customer Base, National, Research & Education</td>
              </tr>
              <tr>
                <td><strong>Country of Constituency</strong></td>
                <td>Botswana</td>
              </tr>
              <tr>
                <td><strong>ASNs, Domains, IP ranges</strong></td>
                <td>-</td>
              </tr>
              <tr>
                <td><strong>Description of Formal Constituency</strong></td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="contact-info-section">
          <h5>Team Contact Information</h5>
          <table>
            <tbody>
              <tr>
                <td><strong>Main Number</strong></td>
                <td>+267 36 85548</td>
              </tr>
              <tr>
                <td><strong>Emergency Number</strong></td>
                <td>+267 73 111260</td>
              </tr>
              <tr>
                <td><strong>Fax Number</strong></td>
                <td>-</td>
              </tr>
              <tr>
                <td><strong>Email</strong></td>
                <td>security@cirt.org.bw</td>
              </tr>
              <tr>
                <td><strong>Postal Address</strong></td>
                <td>
                    <p>Botswana National Computer Security Incident Response Team (BW-CSIRT)</p>
                   <p>Plot No 66459, Spectrum House, Phakalane</p>
                   <p>Private Bag 00495</p>
                   <p>Gaborone </p>
                   </td>
                   </tr>
                   <tr>
                <td><strong>Business Hours</strong></td>
                <td>07:30 to 17:00, Monday to Friday</td>
              </tr>  
              <tr>
                <td><strong>Timezone</strong></td>
                <td>Africa/Gaborone</td>
              </tr>  
              <div className="cryptography-info">
            <h3>Cryptography</h3>
            <pre>
                <p>Type:  RSA/4096    Expires: 2027-01-15</p>
                <p>Fpr: 2519 9509 D545 4A1B 5258 D756 3188 00E6 278D 73A9</p>
                <p>Sub: RSA/4096  Usage: Encrypt  Expires: 2027-01-15</p>
                <p>UID: CSIRT Security &lt;security@cirt.org.bw&gt;</p>
                </pre>
      </div>  
      <div className="memberships-info">
      <h3>Memberships</h3>
      <tr>
         <td><strong>AfricaCERT / OIC-CERT</strong></td>
                <td>Full Member since 23 Jun 2023
                </td>
              </tr>
               </div>

               <div className="classification-info">
              <h3>Clasification</h3> 
              <tr>
                <td><strong>Current State</strong></td>
                <td>Listed
                since 23 Jan 2024</td>
              </tr> 
              <tr>
                <td><strong>Entry Date</strong></td>
                <td>23 Jan 2024</td>
              </tr> 
              </div>
              <div className="history-info">
              <h3>History</h3> 
              <tr>
                <td><strong>Date</strong></td>
                <td>23 Jan 2024</td>
              </tr> 
              </div>
              <tr>
              <td><strong>Description</strong></td>
                <td>Botswana-CSIRT (BW) is now a listed team</td>
              </tr>  
             
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}



export default TeamDetails;
