import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaComments} from "react-icons/fa";
import "./Footer.css"; 
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t, i18n } = useTranslation();
  

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <footer className="footer-container">
  <div className="footer-main">
  <div className="footer-content">
        <div className="footer-section">
          <img
            src="/assets/images/TBAFinal.png"
            alt="About TBA"
            className="footer-image"
          />
          {/* <div className="footer-bottom">
        <div className="footer-privacy">
          <p>Privacy</p>
          <p>Disclaimer</p></div>
        </div> */}
        </div>

        <div className="footer-section">
          <h4>{t('Get in touch')}</h4>
          <ul>
        <li>TrustBroker Africa Service</li>
         <li>c/o WACREN</li>
         <li>VCG Office Complex, IPS Road</li>
         <li>P O Box LG 1279, Accra, Ghana</li>
         </ul>
        </div>

        <div className="footer-section">
          <h4>{t('Important links')}</h4> 
          <ul>
            <li>
              <Link to ="/about/trustbroker-africa" className="link">{t('welcome_message')}</Link>
            </li>
            <li>
              <Link to="/community/media" className="link">{t('Latest News')}</Link>
            </li>
            <li>
              <Link to="/upcomingevents" className="link">{t('Upcoming Event')}</Link>
            </li>
            <li>
              <Link to="/join" className="link">{t('Get Involved')}</Link>
            </li>
          </ul>
        </div>
        
        </div>
       
      
        
        </div>
        
        <div className="footer-partners">
          
            
            <img
            src="/assets/images/footerlogo.jpeg"
            alt="partnerslogo"
            className="partner-logo"
          />
          
      </div>
      
    
      
      

      <div className="footer-bottom1">
          <p>{t('Copyright 2024. TBA. All rights reserved')}</p>
        </div>
   
   
    </footer>

  );
}

export default Footer;



