import React from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import './Registry.css';


function UpdateHistory() {
  const navigate = useNavigate();
  // const [showMore, setShowMore] = useState(false);

  const navigation = (url) => {
      navigate(url);
    };


  return (
    <div className="container">
      <div className="content">
        <h2>Update History</h2>
        <p>On this page you find the history of all of the following updates:</p>
        <ul>
          <li>Newly "listed" teams in the TI database</li>
          <li>status changes - teams going "Accreditation Candidate" or becoming "Accredited"</li>
          <li>Other significant changes related to teams in the TI database</li>
        </ul>
      </div>

      <div className="year-buttons">
      <Button onClick={() => navigation("/year2024")} className="button">
            2024
          </Button>
         
        <Button onClick={() => navigation("/year2023")} className="button">
            2023
          </Button>
        
        </div>
      
    </div>
  );
}

export default UpdateHistory;
