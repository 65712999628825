import React, { useState } from 'react' ;
import './Media.css'; 
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

function Media() {
  const { t, i18n } = useTranslation();
  

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [showMore, setShowMore] = useState(false);

  const handleReadMoreClick = () => {
    setShowMore(!showMore);
  };

  return (
  
        
    //   </section>
    <div className="media-page">
      <section className="events-section">
        <h2 className="section-title">Events</h2>
        <div className="events">
          <div className="event">
            <img src="/assets/images/wacren2021.png" alt="Event" className="event-image"/>
            <p className="event-caption"> {' '}
        <a href="https://indico.wacren.net/event/124/" target="_blank" rel="noopener noreferrer">
        {t('CSIRT training')}
        </a>{' '}
              
              </p>
            

            
          </div>
          <div className="event">
            <img src="/assets/images/BOCRA_TBAII.png" alt="Event" className="event-image"/>
            <p className="event-caption">{' '}
            <a href="https://indico.wacren.net/event/146//" target="_blank" rel="noopener noreferrer">
              {t('community-building workshop')}
              </a>{' '}
              </p>
            

            
          </div>
          <div className="event">
            <img src="/assets/images/NgREN_TBAIII.png" alt="Event" className="event-image"/>
            <p className="event-caption">{' '}
            <a href=" https://indico.wacren.net/event/179/" target="_blank" rel="noopener noreferrer">
           {t('TBA III')} 
              </a>{' '}
              
              </p>
           
          </div>
          
        </div>
      </section>

      {/* New Section Below Events */}

      <section className="media-section">
        <h2 className="section-title">Photos</h2>
        <div className="media-grid photos-grid">
          <div className="media-card">
            <img src="/assets/images/tba1.jpg" alt="Photo 1" className="media-image" />
            <p>{' '}
            <a href="  https://photos.wacren.net/index.php?/category/98" target="_blank" rel="noopener noreferrer">
            {t('TBA III Workshop Abuja')}
              </a>{' '}
              </p>
           
           
          </div>
          
        </div>
      </section> 

    </div>
  );
}

export default Media;
