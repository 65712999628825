import React from 'react';
import './App.css';


const RenuCert=() => {
  return (
    <div className="container">
      <h2>The Research and Education Network for Uganda - Computer Emergency Response Team</h2>
      <h3>CSIRT of Mozambique Research and Education Network</h3>
      <p><strong>Listed</strong> since 16 Jan 2024</p>
      <p className="info-text">This information is provided without guarantee or pro-active maintenance.</p>

      <h3>Fields describing the team</h3>

      <div className="section">
        <h4>Team Details</h4>
        <div className="teams-details">
          <div className="teams-detail">
            <strong>Official Name</strong>
            <p>The Research and Education Network for Uganda - Computer Emergency Response Team</p>
          </div>
          <div className="teams-detail">
            <strong>Short Name</strong>
            <p>RENU-CERT (UG)</p>
          </div>
          <div className="teams-detail">
            <strong>Country</strong>
            <p> 
            <img src="/assets/images/Uganda.png" alt="Uganda Flag" className="flag-icon" />Uganda</p>
          </div>
          <div className="teams-detail">
            <strong>Established</strong>
            <p>05 Aug 2016</p>
          </div>
          <div className="teams-detail">
            <strong>Host Organisation</strong>
            <p>The Research and Education Network for Uganda</p>
          </div>
        </div>
      </div>

      <h3>Constituency</h3>
      <div className="constituency">
        <div className="constituency-detail">
          <strong>Constituency Type</strong>
          <p>Research & Education</p>
        </div>
        <div className="constituency-detail">
          <strong>Country of Constituency</strong>
          <p>Uganda</p>
        </div>
        <div className="constituency-detail">
          <strong>ASNs, Domains, IP ranges</strong>
          <p>327687</p>
           
            <p>196.43.128.0/18</p>
            <p>137.63.128.0/17</p>
            <p>196.43.128.0/18</p>
            <p>102.34.0.0/16</p>
            <p>2c0f:f6d0::/32</p>

        </div>
        <div className="constituency-detail description">
          <strong>Description of Formal Constituency</strong>
          <p>The RENU-CERT's constituency is a bounded constituency, comprising the entire RENU community. This is further broken down into two sub-constituencies:</p>
          <p>RENU Member Institutions - all institutions/entities connected to or served by the Research and Education Network for Uganda (RENU).</p>
           <p>RRENU Secretariat - the entire staff/team that run the operations of the Research and Education Network for Uganda (RENU).</p>
        </div>
      </div>

      <h3>Team Contact Information</h3>
      <div className="contact-infos">
        <div className="contact-details">
          <strong>Main Number</strong>
          <p>+256 783 619 776</p>
          
        </div>
        <div className="contact-details">
          <strong>Emergency Number</strong>
          <p>+256 783 979 515</p>
        </div>
        <div className="contact-details">
          <strong>Fax Number</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Email</strong>
          <p>cert@renu.ac.ug</p>
        </div>
        <div className="contact-details">
          <strong>Other Contact</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Postal Address</strong>
          <p>Rashida Towers</p>
        <p>Plot 6B, Mabua Road, Kololo</p>
         <p>Kampala</p>

        </div>
        <div className="contact-details">
          <strong>Automated Reporting E-Mail</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Autom. Rep. Supported Formats</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Business Hours</strong>
          <p>Monday - Friday, 08:00 - 17:00, EAT</p>
          
        </div>
        <div className="contact-details">
          <strong>Timezone</strong>
          <p>Africa/Kampala</p>
        </div>
      </div>

      <h3>Cryptography</h3>
      <div className="cryptography">
      <p>Type: RSA/2048    Expires: 2024-08-22</p>
        <p>Fpr: C5EA 4C0E 90F6 DFB1 0531 1F90 E55D AF0A 2BC0 253B</p>
        <p>Sub: RSA/4096  Usage: Encrypt  Expires: 2026-01-19</p>
        <p>UID: RENU-CERT &lt;cert@renu.ac.ug&gt;</p>
        
</div>

        <div className="section">
        <h3>Classification</h3>
        <div className="classifications-infos"></div>
        <div className="classification-details">
          <strong>Current State	</strong>
          <p>Listed since 16 Jan 2024</p>
          </div>

         <div className="classification-details">
          <strong> Entry Date	</strong>
          <p>16 Jan 2024</p>
          </div>

          <div className="section">

        <h3>History</h3>
        <div className="history-infos"></div>
        <div className="historys-details">
          <strong>Date	</strong>
          <p> 16 Jan 2024</p>
          </div>

         <div className="historys-details">
          <strong>Describtion</strong>
          <p>RENU-CERT (UG) is now a listed team</p>
          </div> 
        </div>
        </div>
        </div>
    //   </div>
  );
};

export default  RenuCert;
