import React from 'react';
import './App.css';
import { Link } from 'react-router-dom';


const AccreditedTeamsList = () => {
  return (
    <div className="teamlist">
      <p>
        The following list contains all <strong>"accredited"</strong> teams. 
        This list contains the same information as the <Link to="/teamlist" className="link"> list by countries</Link>, but is sorted by team names.
      </p>
      <p>
        All <strong>"listed"</strong> teams are contained in the <Link to="/csirlist" className="link"> full list</Link>.
      </p>
    </div>
  );
};

export default AccreditedTeamsList;
