
import React from 'react';
import { FaLinkedin } from 'react-icons/fa';
import './About.css'; 
import { useTranslation } from 'react-i18next';

function SteeringCommittee() {
  const { t, i18n } = useTranslation();
  

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const committeeMembers = [
    { 
      name: 'David Moepeng', 
      description: t('david_name'),
      linkedin: 'https://www.linkedin.com/in/david-moepeng-msc-25923621/', 
      image: '/assets/images/David Moepeng.jpg' 
    },
    { 
      name: 'Cedric Mbeyet', 
      description: t('cedric_name'), 
      linkedin: 'https://www.linkedin.com/in/cmbeyet/?originalSubdomain=mu/', 
      image: '/assets/images/Cedric.jpeg' 
    },
    { 
      name: 'Dr Lourino Chemane', 
      description: t('lourino_name'), 
      linkedin: 'https://www.linkedin.com/in/lourino-chemane-b64547106/?originalSubdomain=mz/', 
      image: '/assets/images/Lourino.jpg' 
    },
    { 
      name: 'Omo-Oaiya', 
      description: t('omo_name'), 
      linkedin: 'https://www.linkedin.com/in/omooaiya/', 
      image: '/assets/images/Omo.png' 
    },
    {
      name: `Open CSIRT Foundation `,
      description: t('open CSIRT_name'), 
     
      linkedin: 'https://www.linkedin.com/company/open-csirt-foundation/',
      image: '/assets/images/opencsirt.jpeg'
    },
    { 
      name: 'Perpetus-Houngbo', 
      description: t('houngbo_name'), 
      linkedin: 'https://www.linkedin.com/in/perpetusjacqueshoungbo/', 
      image: '/assets/images/Perpetus.jpg' 
    }
  ];

  return (
    <div className="steering-committee-container">
      <h2 className="headline">{t('steering_committee')}</h2>
      <div className="committee-members">
        {committeeMembers.map((member, index) => (
          <div key={index} className="member-card">
            <img src={member.image} alt={`${member.name}`} className="member-image" />
            <div className="member-name">
              <h3>
                {member.name} 
               
              </h3>
              <p>{member.description}</p>
            </div>
            <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="linkedin-icon">
                  <FaLinkedin /> {t('connect')}
                </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SteeringCommittee;
