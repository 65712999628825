
import React, { useState } from 'react';
import './Join.css'; // Ensure you import the CSS file
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Join() {
  const { t, i18n } = useTranslation();
  

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);

  const handleReadMoreClick = () => {
    setShowMore(!showMore);
  };

  const navigation = (url) => {
    navigate(url);
  };

 

  return (
    <div className="join-page">
      <h1>{t('join_tba')}</h1>
      <p>
        {t('security and Incident Response teams')} {' '}
        <a href="https://www.trusted-introducer.org/" target="_blank" rel="noopener noreferrer">
          {t('TI Service')}
        </a>{' '}
        {t('compatible')}.
      </p>
      <p>
        {t('safeguard')}.
      </p>
      
      {showMore && (
        <div className="additional-content">
          <p>{t('categories')}:</p>
          {/* <ul className="main-list"> */}
            {/* <li> */}
              {/* {t('teams are')} */}
              <ul className="sub-list">
                <li>{t('listed')}, {t('provides basic information')}</li>
                <li>{t('accredited')}, {t('best practices')};</li>
                <li>{t('security experts')} {t('participate')}.</li>
                </ul>
        <div className="additional-contents">       
      <p>{t('TBA Accreditation')}.
      </p>
      <p>{t('first team')} {' '} 
        <a href="https://www.trusted-introducer.org/processes/standards.html" target="_blank" rel="noopener noreferrer">
       
        {t('are explained here')}
        </a>.{' '}
      </p>
      </div>
     
      
            {/* </li> */}
          {/* </ul> */}
        </div>
        
      )}
       <Button className='dflex items-center justify-center text-center mb-4 btn' onClick={handleReadMoreClick}>
        {showMore ? t('Show Less') : t('Read More')}
      </Button>

     
          
      {/* <h3 className='mt-4 text-center'>Join TBA as:</h3> */}
      <div className='card-container'>
        <div className="card-main">
          <div className="card__content">
            <h2 className="card__title">{t('TBA Listed team')}</h2>
            <div className="card__img">
              <img src="assets/images/listed.jpeg" alt="TBA Listed team" />
            </div>
          </div>
          <Button onClick={() => navigation("/join/listed-team")} className="button">
            {t('joins')}
          </Button>
        </div>
        <div className="card-main">
          <div className="card__content">
            <h2 className="card__title">{t('TBA Accredited team')} </h2>
            <div className="card__img">
              <img src="assets/images/accredited.jpeg" alt="TBA Accredited team" />
            </div>
          </div>
          <Button onClick={() => navigation("/join/accredited-team")} className="button">
          {t('joins')}
          </Button>
        </div>
        <div className="card-main">
          <div className="card__content">
            <h2 className="card__title">{t('TBA Associated expert')}</h2>
            <div className="card__img">
              <img src="assets/images/associated.jpeg" alt="TBA Associated expert" />
            </div>
          </div>
          <Button onClick={() => navigation("/join/associated-team")} className="button">
          {t('joins')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Join;
