import React from 'react';
import './App.css';


const CertifiedTeamsList = () => {
  return (
    <div className="teamlist">
      <p>
        The following list contains all <strong>"certified"</strong> teams. This list contains the same information as the <a href='./teamlist'>list by countries</a>, but is sorted by team names.
      </p>
      <p>
        All <strong>"listed"</strong> teams are contained in the <a href='./csirlist'>full list</a>.
      </p>
      </div>
   
  );
};

export default CertifiedTeamsList;
