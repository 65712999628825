import React from 'react';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { FaFacebook, FaTwitter, FaInstagram, FaComments } from "react-icons/fa";



function MainNavbar() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

   return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" style={{position: "fixed", zIndex: 9999, width: '100%', paddingLeft: 20,}}>
    {/* <Container> */}
      <Navbar.Brand as={Link} to="/">
       <img src='/assets/images/TBAFinal.png' alt='tba logo' style={{width: "40%"}} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <NavDropdown title={t('welcome_message')} id="collapsible-nav-dropdown">
            <NavDropdown.Item as={Link} to="/about/trustbroker-africa">
            {t('about_us')}
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/about/steering-committee">
            {t('steering')}
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/about/resources">
            {t('resources')}
            </NavDropdown.Item>
          </NavDropdown>
          {/* <NavDropdown title="Registry" id="collapsible-nav-dropdown">
          <NavDropdown.Item as={Link} to="/registry/teams-directory">
             Teams Directory
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/registry/recent-updates">
             Recent Updates
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/registry/update-history">
             Update History
            </NavDropdown.Item>
            </NavDropdown> */}
        <li class="nav-item dropdown">

	   <a class="nav-link dropdown-toggle" href="https://trustbroker-dev.ren.africa/registry/" rel="noopener noreferrer" id="registryDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    Registry
</a>

                        <ul class="dropdown-menu" aria-labelledby="registryDropdown">
                            <li><a class="dropdown-item" href="index.html">Team Database</a></li>
                        
                            <li><a class="dropdown-item" href="updates.html">Recent Updates</a></li>
                            <li><a class="dropdown-item" href="history/index.html">Update History</a></li>
                        </ul>
                    </li> 

          
          <Nav.Link as={Link} to="/join">
          {t('join')}
          </Nav.Link>
          <NavDropdown title= {t('community')} id="collapsible-nav-dropdown">
            <NavDropdown.Item as={Link} to="/community/media">
             {t('media')}
            </NavDropdown.Item>
            {/* <NavDropdown.Item as={Link} to="/community/blog">
             Blog
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/community/giz-programme">
             GIZ Programme
            </NavDropdown.Item> */}
            </NavDropdown>
          {/* <Nav.Link as={Link} to="/media">
            Media
          </Nav.Link> */}
          <Nav.Link as={Link} to="/support">
            {t('support')}
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      {/* <div className="socials"> */}
      {/* <div className="social-icons"> */}
            {/* <a href="https://facebook.com">
              <FaFacebook/>
            </a> */}
            {/* <a href="https://twitter.com">
              <FaTwitter />
            </a> */}
            {/* <a href="https://instagram.com">
              <FaInstagram />
            </a>
          
          <a href="/chat">
            <FaComments />
          </a> */}
      {/* </div> */}
      {/* </div> */}
      
    {/* </Container> */}
    <div>
       {/* <h1>{t('welcome_message')}</h1>
      <p>{t('')}</p>  */}

      <button onClick={() => changeLanguage('en')}>English</button>
      <button onClick={() => changeLanguage('fr')}>Français</button>
    </div>
  </Navbar>
   )
}

export default MainNavbar
