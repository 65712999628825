import React from 'react';
import './Join.css'; 
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function ListedTeam() {
  const { t, i18n } = useTranslation();
  

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
  
    <div className="listed-team-page">
      <h1 className="title">{t('joiningTBA')}</h1>
      <p className="describe">
      <strong>{t('TBA community')}:</strong> {t('registered listed')}.
      </p>
      <p>{t('secure sponsors')}.
      </p>
      <p>{t('For now, please')}:
      </p>
      <ol>
        <li>{t('fill out the')} {' '}
        <Link to="/tbatemplateform" className="link">
        {t('form')} 
        </Link> 
         {t(' for new listing candidates')} </li>
        <li>{t('submit the filled out form')}  <Link to="/support" className="link">
                  tba@trustbroker.africa
        </Link> 
        </li>
      </ol>
      <p>{t('process')}!
      </p>

      <div className="image-placeholder">
        <img src="/assets/images/tba_listing.png" alt="Placeholder for Listed Team" className="placeholder-image" />
      </div>
      <div className="process-section">
        <h2>{t('The Process for new Listing Candidates')}</h2>
        <p>
         {t('already listed')}:
        </p>
        <ul>
          <li>{t('listing application')}.</li>
          <li>{t('applying candidate')}. </li>
          <li>{t('objections')}.</li>
          <li>{t('outcome')}.</li>
          <li>{t('rejected teams')}.</li>
          <li>{t('automatically rejected')}.</li> 
          <li>{t('rejected team')}.</li>
        </ul>

      </div>
      <div className="listedteam-page">
      <h3>{t('Re-Listing every three years')}</h3>
      <p>
        {t('trust level')}  <strong>{t('not become')}</strong> {t('accredited within')}.
      </p>
      <div className="image-placeholder">
        <img src="/assets/images/tba_relisting.png" alt="Placeholder for Re-Listing" />
      </div>
      <p>{t('addition')} :</p>
      <ul>
        <li>{t('having')} </li>
        <li>{t('valid')} </li>
        <li>{t('latest update')}</li>
      </ul>
      <p>{t('on-going')}!
      </p>
      <div className="related-materials">
         <h4>{t('Related Materials')}</h4>
        <p>
        {t('Useful materials for this process are')}:</p>
        <ul>
          <li>{t('form for registering new listing candidates')}
          </li>
        </ul>
      </div>
    </div>
    </div>
   
  );
}

export default ListedTeam;


