import React from 'react';
import './App.css';


const GmCSIRT = () => {
  return (
    <div className="container">
      <h2>gmCSIRT (GM)</h2>
      <h3>Gambia National Computer Security and Incident Response Team</h3>
      <p><strong>Listed</strong> since 28 Nov 2023</p>
      <p className="info-text">This information is provided without guarantee or pro-active maintenance.</p>

      <h3>Fields describing the team</h3>

      <div className="section">
        <h4>Team Details</h4>
        <div className="teams-details">
          <div className="teams-detail">
            <strong>Official Name</strong>
            <p>Gambia National Computer Security and Incident Response Team</p>
          </div>
          <div className="teams-detail">
            <strong>Short Name</strong>
            <p>gmCSIRT (GM)</p>
          </div>
          <div className="teams-detail">
            <strong>Country</strong>
            <p>🇬🇲 Gambia</p>
          </div>
          <div className="teams-detail">
            <strong>Established</strong>
            <p>-</p>
          </div>
          <div className="teams-detail">
            <strong>Host Organisation</strong>
            <p>Public Utilities Regulatory Authority (PURA)</p>
          </div>
        </div>
      </div>

      <h3>Constituency</h3>
      <div className="constituency">
        <div className="constituency-detail">
          <strong>Constituency Type</strong>
          <p>Government</p>
        </div>
        <div className="constituency-detail">
          <strong>Country of Constituency</strong>
          <p>-</p>
        </div>
        <div className="constituency-detail">
          <strong>ASNs, Domains, IP ranges</strong>
          <p>-</p>
        </div>
        <div className="constituency-detail description">
          <strong>Description of Formal Constituency</strong>
          <p>The constituency of gmCSIRT is composed of all Government departments and core agencies of the Gambian State. In addition to Government departments and agencies, gmCSIRT is the CSIRT for all Digital Service Providers and Operators of Essential Services.</p>
          <p><strong>Note:</strong> To report an incident as a constituent, you must be an official in a Government department or agency identified as Critical National Information Infrastructure holder (CNII) with the authority to make such a report.</p>
        </div>
      </div>

      <h3>Team Contact Information</h3>
      <div className="contact-infos">
        <div className="contact-details">
          <strong>Main Number</strong>
          <p>+220 200 0919</p>
          <p>+220 788 7686</p>
        </div>
        <div className="contact-details">
          <strong>Emergency Number</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Fax Number</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Email</strong>
          <p>info@gmcsirt.gm</p>
        </div>
        <div className="contact-details">
          <strong>Other Contact</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Postal Address</strong>
          <p>gmCSIRT</p>
          <p>Public Utilities Regulatory Authority (PURA)</p>
          <p>94 Kairaba Avenue, Fajara</p>
        </div>
        <div className="contact-details">
          <strong>Automated Reporting E-Mail</strong>
          <p>incident@gmcsirt.gm</p>
        </div>
        <div className="contact-details">
          <strong>Autom. Rep. Supported Formats</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Business Hours</strong>
          <p>08:00 to 16:00 Monday to Thursday</p>
          <p>08:00 to 13:00 Friday</p>
        </div>
        <div className="contact-details">
          <strong>Timezone</strong>
          <p>Africa/Banjul</p>
        </div>
      </div>

      <h3>Cryptography</h3>
      <div className="cryptography">
        <p>Type: EdDSA/256 &nbsp; Expires: 2025-05-09</p>
        <p>Fpr: 8277 9BE9 1B08 37EF 31E3 F650 42C3 CF47 BC28 6328</p>
        <p>Sub: ECDH/256 &nbsp; Usage: Encrypt &nbsp; Expires: 2025-05-09</p>
        <p>UID: gmCSIRT &lt;info@gmcsirt.gm&gt;</p>
</div>
        <div className="section">
        
        <h3>Memberships</h3>
      <div className="memberships-infos"></div>
        <div className="memberships-details">
          <strong>AfricaCERT / OIC-CERT</strong>
          <p>Full Member since</p> 
        </div>

        <div className="section">
        <h3>Classification</h3>
        <div className="classifications-infos"></div>
        <div className="classification-details">
          <strong>Current State	</strong>
          <p>Listed since 28 Nov 2023</p>
          </div>

         <div className="classification-details">
          <strong> Entry Date	</strong>
          <p>18 Nov 2022</p>
          </div>

          <div className="section">

        <h3>History</h3>
        <div className="history-infos"></div>
        <div className="historys-details">
          <strong>Date	</strong>
          <p> 28 Nov 2023</p>
          </div>

         <div className="historys-details">
          <strong>Describtion</strong>
          <p>gmCSIRT (GM) is now a listed team</p>
          </div> 
        </div>
        </div>
        </div>
      </div>
  );
};

export default GmCSIRT;
