import React, { useState } from "react";
import './App.css'; 

function Upcomingevents() {
  const facilitators = [
    {
      name: "Don Stikvoort",
      subtitle: "(OCF, SIM3 Auditor)",
      description:
        `Don Stikvoort is founder of the companies “S-CURE” and “Cross Your Limits”. S-CURE offers senior consultancy in the area of cyber security – specialising in CSIRT matters. Cross Your Limits coaches and trains in the human area. Based in Europe, Don’s client base is global.`,
      fullDescription: `After his MSc degree in Physics, he became Infantry platoon commander in the Dutch Army. In 1988 he joined the Dutch national research network SURFnet. In that capacity he was among the pioneers who together created the European Internet since November 1989. He recognised “security” as a future concern in 1991, and was chair of the 2nd CSIRT in Europe (now SURFcert) from 1992-8, and FIRST member since 1992. Today Don is a FIRST Liaison Member.

        Together with Klaus-Peter Kossakowski he initiated and built the closer cooperation of European CSIRTs starting in 1993 – this led to the emergence of TF-CSIRT in 2000. In 1998 he finished the "Handbook for Computer Security Incident Response Teams (CSIRTs)" together with Kossakowski and Moira J. West-Brown of CERT/CC. He was active in the IETF and RIPE (co-creator of the IRT-object). Don chaired the Program Committee for the 1999 FIRST conference in Brisbane, Australia, and kick-started the international FIRST Secretariat in the same year. From 2001-2011 his company ran TF-CSIRT’s Trusted Introducer service. He wrote and taught several training modules for the CSIRT community.

        In 1998 Don started his first company. A first assignment was to build the network connecting over 10,000 schools in The Netherlands. Many CSIRTs were created with his help and guidance, among which the Dutch national team (NCSC-NL). Second opinions, audits and maturity assessments in this field have become a specialty – and in that capacity Don developed SIM3 in 2008, the maturity model for CSIRTs which is used worldwide today for maturity assessments and certifications. SIM3 has is now under the wings of the “Open CSIRT Foundation” (OCF). Don was one of the founders in 2016 and now chairs its board.

        Starting in 1999, Don was certified in NLP, Time Line Therapy®, Coaching and Hypnotherapy, and brought that under the wing of “Cross Your Limits”, which portfolio is life & executive coaching, and training courses in what Don likes to call “human arts”. He also trains communicators, presenters and trainers, including many in the CSIRT field.

        Don thrives as motivational and keynote speaker. He enjoys to share his views on how the various worlds of politics, economics, psychology and daily life, but also cyber security, all intertwine and relate – and how deeper understanding and a better ability to express ourselves, increase our ability to bring good change to self as well as the world around us. He has discussed such topics all over the world, from Rome to the Australian Outback. His goal is to challenge his audience to think out-of-the-box, and motivate them to be the difference that makes the difference, along the lines of the old African proverb:

        “If you think you’re too small to make a difference, try sleeping in a closed room with a mosquito”.`,

      image: "/assets/images/Don Stikvoort.jpeg",
    },

    {
      name: " Jon Flaherty",
      subtitle: "(Title or Role)",
      description:
        "Jon Flaherty -  Joining Shadowserver in 2016, Jon brings a range of skills and experience from UK Law Enforcement cyber crime investigation to the Foundation.",
      fullDescription: `Engaging in consumer outreach, incident handling and the training of constituents in the use of Shadowserver public benefit services allows 
      Jon the opportunity to deliver current threats and insights to all areas of the cyber community of specific interest to Jon is international liaison and with 
      the support of key public/private sector partners, the upskilling of developing cyber regions and National CERTs to ensure the effective use and 
      understanding of Shadowserver’s bespoke datasets for a more secure internet.`,
      image: "/assets/images/jon-flaherty.png",
    },
    {
      name: "Omo Oaiya",
      subtitle: "(SIM3 Auditor)",
      description: 
        `Omo Oaiya is the Chief Strategy Officer of the West and Central 
        Research and Education Network (WACREN).  He was the pioneer CTO of the RREN and continues to work on the technical development
        of high-capacity Internet network infrastructure for research and education.`,
      fullDescription: `He leads LIBSENSE, an initiative aimed at building the information management capability of librarians and researchers, 
        fostering communities of practice, and strengthening local services to support open science and research in Africa.  A certified SIM3 Auditor, he has keen interests in the establishment and maturity of security teams (CSIRTs) in NRENs.`,
      image: "/assets/images/Omo.png",
    },
  ];

  const [expanded, setExpanded] = useState(null);
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleReadMore = (index) => {

    setExpanded(expanded === index ? null : index); 
  };
  
    const toggleFAQ = (index) => {
      setOpenFAQ(openFAQ === index ? null : index);
    };

  return (
    <div className="page-container"> 
      {/* Certification Training Banner */}
      {/* <div
        className="certification-training"
        style={{
          backgroundImage: 'url("/assets/images/background.jpg")',
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="breadcrumbs"> 
          <span>HOME</span> / <span className="highlight">SIM3</span>
        </div> */}

        {/* Main content */}
        {/* <div className="content">
          <h7>TrustBroker Africa (TBA) IV</h7>
          <div >
          <h8>SIM3 Auditor Certification Training</h8>
        </div>
</div> */}
        {/* Background circles */}
        {/* <div className="circles">
          <div className="circle-big"></div>
          <div className="circle-small"></div>
        </div> */}
      {/* </div> */}

      {/* Banner Section */}
      <div className="banner">
        <img
          src="/assets/images/sim3slider.jpeg"
          alt="SIM3 Auditor Certification Training"
          className="banner-image"
        />
      </div>

      {/* Event Details Section */}
      <div className="event-details">
        <h2 className="event-title">SIM3 Auditor Certification Training</h2>
        <p className="event-description">
          The TrustBroker Africa (TBA) IV is a training and community-building event hosted by Le Forum sur 
          la Gouvernance de l’Internet au Bénin ( {' '} 
            <a href="https://fgi.bj/" target="_blank" rel="noopener noreferrer"> 
            FGI Bénin </a> {' '})  in collaboration with WACREN, the Open CSIRT Foundation (OCF), and the {' '} 
            <a href="https://www.shadowserver.org/" target="_blank" rel="noopener noreferrer">
            ShadowServer Foundation </a>. {''}
        </p>
        <p className="event-description">
          The SIM3 Auditor Certification Training is an intensive program designed to equip 
          cybersecurity professionals with the knowledge and skills to assess and improve the maturity of 
          Computer Security Incident Response Teams (CSIRTs) within their organizations.
        </p>

        <div className="important-links-section">
      <h2 className="section-titles">Important Links</h2>
      </div>

      
      <div className="links-container">
        {/* Slides/Docs Button */}
        <a href="/slides" className="link-button">
          <i className="icon-book"></i> 
          Slides/Docs
        </a>
        
        {/* Videos Button */}
        <a href="https://video.wacren.net" className="link-button">
          <i className="icon-video"></i> 
          Videos
        </a>

        {/* Photos Button */}
        <a href="https://photos.wacren.net/" className="link-button">
          <i className="icon-photo"></i> 
          Photos
        </a>
      </div>
      <div className="fascilitator-header-section"></div>
      <h2 className="section-titles"> Fascilitators </h2>

      {/* Facilitators Section */}
      <div className="facilitators-section">
        {facilitators.map((facilitator, index) => (
          <div key={index} className="facilitator-container">
           <div className="facilitator-image-container">
           <img
              src={facilitator.image}
              alt={facilitator.name}
              className="facilitator-image"
            />
           </div>
            <div className="text-container">
              <h2 className="name">{facilitator.name}</h2>
              <h4 className="subtitles">{facilitator.subtitle}</h4>
              <p className="description">{facilitator.description}</p>
              
              {expanded === index && (
                <div className="full-description">
                  <p>{facilitator.fullDescription}</p>
                </div>
              )}
              
              <button className="button" onClick={() => toggleReadMore(index)}>
                {expanded === index ? "Read less" : "Read more"}
              </button>
            </div>
          </div>
        ))}
        <div className="collaboration-faq-container">
      {/* Collaborating Organizations Section */}
      <section className="collaborating-organizations">
        <h2>Collaborating Organizations</h2>
        <div className="organization-logos">
          <div className="org-group">
            <h4>Organized by</h4>
            <img src="/assets/images/WACREN.jpeg" alt="WACREN Logo" />
          </div>
          <div className="org-group">
            <h4>Host</h4>
            <img src="/assets/images/fgBenin.jpeg" alt="FG Benin Logo" />
          </div>
          <div className="org-group">
            <h4>Facilitators</h4>
            <div className="org-sub-group">
            <img src="/assets/images/CSIRT.jpeg" className="csirt" alt="Open CSIRT Logo" />
            <img src="/assets/images/ShadowServer.jpeg" className="shadowserver" alt="ShadowServer Logo" />
            </div>
          </div>
          <div className="org-group">
            <h4>With support from</h4>
            <div className="org-sub-group">
            <img src="/assets/images/AC3_logo.png" alt="AfricaConnect Logo" />
            <img src="/assets/images/eu_flag.png" alt="EU Logo" />
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      
      {/* <section className="faq-sections"> */}
        {/* <h9>Frequently Asked Questions</h9> */}
        {/* <div className="faq-item" onClick={() => toggleFAQ(1)}> */}
          {/* <div className="faq-question">
            <span>+</span>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div> */}
          {/* {openFAQ === 1 && (
            <div className="faq-answer">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                id eros et neque finibus rhoncus.
              </p>
            </div>
          )} */}
        {/* </div> */}

        {/* <div className="faq-item" onClick={() => toggleFAQ(2)}>
          <div className="faq-question">
            <span>+</span>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
          {openFAQ === 2 && (
            <div className="faq-answer">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                id eros et neque finibus rhoncus.
              </p>
            </div>
          )}
        </div> */}

        {/* <div className="faq-item" onClick={() => toggleFAQ(3)}>
          <div className="faq-question">
            <span>+</span>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
          {openFAQ === 3 && (
            <div className="faq-answer">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                id eros et neque finibus rhoncus.
              </p>
            </div>
          )}
        </div> */}
      {/* </section> */}
    </div>
      </div>
      </div>
    </div>
  );
}

export default Upcomingevents;
