import React from 'react';
import './App.css';


const KECirt=() => {
  return (
    <div className="container">
      <h2>National KE-CIRT/CC (KE)</h2>
      <h3>The National Kenya Computer Incident Response Team - Coordination Centre</h3>
      <p><strong>Listed</strong> since 08 Jan 2024</p>
      <p className="info-text">This information is provided without guarantee or pro-active maintenance.</p>

      <h3>Fields describing the team</h3>

      <div className="section">
        <h4>Team Details</h4>
        <div className="teams-details">
          <div className="teams-detail">
            <strong>Official Name</strong>
            <p>The National Kenya Computer Incident Response Team - Coordination Centre</p>
          </div>
          <div className="teams-detail">
            <strong>Short Name</strong>
            <p>National KE-CIRT/CC (KE)</p>
          </div>
          <div className="teams-detail">
            <strong>Country</strong>
            <p> 
            <img src="/assets/images/Kenya.png" alt="Kenya Flag" className="flag-icon" /> Kenya</p>
          </div>
          <div className="teams-detail">
            <strong>Established</strong>
            <p>01 Oct 2012</p>
          </div>
          <div className="teams-detail">
            <strong>Host Organisation</strong>
            <p>The Communications Authority of Kenya (CA)</p>
          </div>
        </div>
      </div>

      <h3>Constituency</h3>
      <div className="constituency">
        <div className="constituency-detail">
          <strong>Constituency Type</strong>
          <p>Government</p>
        </div>
        <div className="constituency-detail">
          <strong>Country of Constituency</strong>
          <p>Kenya</p>
        </div>
        <div className="constituency-detail">
          <strong>ASNs, Domains, IP ranges</strong>
          <p>-</p>
        </div>
        <div className="constituency-detail description">
          <strong>Description of Formal Constituency</strong>
          <p>-</p>
        </div>
      </div>

      <h3>Team Contact Information</h3>
      <div className="contact-infos">
        <div className="contact-details">
          <strong>Main Number</strong>
          <p>+254 703 042000</p>
          
        </div>
        <div className="contact-details">
          <strong>Emergency Number</strong>
          <p>+254 703 042700</p>
        </div>
        <div className="contact-details">
          <strong>Fax Number</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Email</strong>
          <p>incidents@ke-cirt.go.ke</p>
        </div>
        <div className="contact-details">
          <strong>Other Contact</strong>
          <p>Twitter: CA_Kenya,</p>
          <p>Facebook: CAOKenya</p>
        </div>
        <div className="contact-details">
          <strong>Postal Address</strong>
          <p>P.O. Box 14448, Nairobi 00800</p>
        </div>
        <div className="contact-details">
          <strong>Automated Reporting E-Mail</strong>
          <p>incident@gmcsirt.gm</p>
        </div>
        <div className="contact-details">
          <strong>Autom. Rep. Supported Formats</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Business Hours</strong>
          <p>08:00 to 17:00 Monday to Friday</p>
          
        </div>
        <div className="contact-details">
          <strong>Timezone</strong>
          <p>Africa/Nairobi</p>
        </div>
      </div>

      <h3>Cryptography</h3>
      <div className="cryptography">
      <p>Type: RSA/4096    Expires: 2036-05-23</p>
        <p>Fpr: 6D5A 06BB B073 EBF2 7DCA 7944 70CC 1731 3B9A 75F3</p>
        <p>Sub: ERSA/4096  Usage: Encrypt  Expires: 2036-05-23</p>
        <p>UID: National KE-CIRT  &lt;incidents@ke-cirt.go.ke&gt;</p>
        <p>UID: National KE-CIRT  &lt;cirt@ca.go.ke&gt;</p>
</div>
        <div className="section">
        
        <h3>Memberships</h3>
      <div className="memberships-infos"></div>
        <div className="memberships-details">
          <strong>FIRST</strong>
          <p>Full Member since 30 Jul 2015</p> 
        </div>
        <div className="memberships-details">
          <strong>AfricaCERT / OIC-CERT</strong>
          <p>Full Member since </p> 
        </div>

        <div className="section">
        <h3>Classification</h3>
        <div className="classifications-infos"></div>
        <div className="classification-details">
          <strong>Current State	</strong>
          <p>Listed since 18 Jan 2024</p>
          </div>

         <div className="classification-details">
          <strong> Entry Date	</strong>
          <p>17 Nov 2022</p>
          </div>

          <div className="section">

        <h3>History</h3>
        <div className="history-infos"></div>
        <div className="historys-details">
          <strong>Date	</strong>
          <p> 08 Jan 2024</p>
          </div>

         <div className="historys-details">
          <strong>Describtion</strong>
          <p>National KE-CIRT/CC (KE) is now a listed team</p>
          </div> 
        </div>
        </div>
        </div>
      </div>
  );
};

export default KECirt;
