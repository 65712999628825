import React from 'react';
import './App.css';


const CertifiedList = () => {
  return (
    <div className="teamlist">
      <p>
        The following list contains all <strong>"certified"</strong> teams. This list contains the same information as the <a href='./teamlist'>alphabetical list </a>, but is sorted by team names.
      </p>
      <p>
        All <strong>"listed"</strong> teams are contained in the <a href='./csirlist'>full list</a>.
      </p>
    </div>
  );
};

export default CertifiedList;
