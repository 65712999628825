// ReadMore.js
import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import './App.css';

function ReadMore() {
  return (
    <div className="read-more-page">
      <h2>More About TrustBroker Africa</h2>
      <p>
        Welcome to the extended section where you can find more detailed information about TrustBroker Africa. 
        We are dedicated to providing a platform that supports and enhances the work of various teams and experts across Africa. 
        Our services are tailored to meet the specific needs of our members, and we strive to offer a comprehensive range of resources and solutions.
      </p>
      <p>
        As a member of TrustBroker Africa, you will have access to a wide network of professionals and organizations that share our commitment to excellence and integrity. 
        Our goal is to foster a collaborative environment where members can share knowledge, resources, and opportunities.
      </p>
      <Link to="/">
        <Button variant="primary">Back to Home</Button>
      </Link>
    </div>
  );
}

export default ReadMore;
