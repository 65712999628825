import React from 'react';
import './App.css';


const SACsirt=() => {
  return (
    <div className="container">
      <h2>SA NREN CSIRT (ZA)</h2>
      <h3>The South African NREN (research and education sector) Computer Security Incident Response Team</h3>
      <p><strong>Listed</strong> since 28 Nov 2023</p>
      <p className="info-text">This information is provided without guarantee or pro-active maintenance.</p>

      <h3>Fields describing the team</h3>

      <div className="section">
        <h4>Team Details</h4>
        <div className="teams-details">
          <div className="teams-detail">
            <strong>Official Name</strong>
            <p>The South African NREN (research and education sector) Computer Security Incident Response Team</p>
          </div>
          <div className="teams-detail">
            <strong>Short Name</strong>
            <p>SA NREN CSIRT (ZA)</p>
          </div>
          <div className="teams-detail">
            <strong>Country</strong>
            <p>
            <img src="/assets/images/southafrica.jpg" alt="South Africa Flag" className="flag-icon" /> South Africa</p>
          </div>
          <div className="teams-detail">
            <strong>Established</strong>
            <p>01 Mar 2016</p>
          </div>
          <div className="teams-detail">
            <strong>Host Organisation</strong>
            <p>Council for Scientific and Industrial Research (CSIR) (SANReN host) and the Tertiary Education and Research Network of South Africa (TENET).</p>
          </div>
        </div>
      </div>

      <h3>Constituency</h3>
      <div className="constituency">
        <div className="constituency-detail">
          <strong>Constituency Type</strong>
          <p>Research & Education</p>
        </div>
        <div className="constituency-detail">
          <strong>Country of Constituency</strong>
          <p>South Africa</p>
        </div>
        <div className="constituency-detail">
          <strong>ASNs, Domains, IP ranges</strong>
          <p>2018</p>
          <p>*.ac.za</p>
          
          <p>128.91.79.156/30</p>
<p>137.214.0.0/15</p>
<p>143.128.0.0/16</p>
<p>143.160.0.0/16</p>
<p>146.64.0.0/16</p>
<p>146.141.0.0/16</p>
<p>146.182.0.0/16</p>
<p>146.230.0.0/15</p>
<p>146.232.0.0/16</p>
<p>152.106.0.0/16</p>
<p>152.112.0.0/16</p>
<p>154.114.0.0/21</p>
<p>154.114.8.0/24</p>
<p>154.114.10.0/23</p>
<p>154.114.13.0/24</p>
<p>154.114.16.0/29</p>
<p>154.114.16.128/25</p>
<p>154.114.17.0/24</p>
<p>154.114.18.0/28</p>
<p>154.114.22.0/25</p>
<p>154.114.24.0/25</p>
<p>154.114.25.0/24</p>
<p>154.114.27.0/24</p>
<p>154.115.0.0/17</p>
<p>155.232.7.40/29</p>
<p>155.232.7.216/29</p>
<p>155.232.8.36/30</p>
<p>155.232.14.0/29</p>
<p>155.232.14.32/29</p>
<p>155.232.14.96/29</p>
<p>155.232.27.80/29</p>
<p>155.232.31.16/28</p>
<p>155.232.56.0/24</p>
<p>155.232.81.0/24</p>
<p>155.232.87.0/24</p>
<p>155.232.92.0/24</p>
<p>155.232.144.176/29</p>
<p>155.232.186.0/24</p>
<p>155.232.188.160/28</p>
<p>155.232.192.0/24</p>
<p>155.232.200.0/30</p>
<p>155.232.240.0/24</p>
<p>155.232.242.0/25</p>
<p>155.232.242.128/26</p>
<p>155.232.242.192/27</p>
<p>155.232.251.0/24</p>
<p>155.238.0.0/16</p>
<p>155.240.160.0/19</p>
<p>155.240.192.0/18</p>
<p>163.200.0.0/16</p>
<p>168.172.0.0/23</p>
<p>168.172.20.0/22</p>
<p>168.172.24.0/21</p>
<p>168.172.41.0/24</p>
<p>168.172.43.0/24</p>
<p>168.172.47.0/24</p>
<p>168.172.48.0/24</p>
<p>168.172.87.0/24</p>
<p>168.172.88.0/23</p>
<p>168.172.128.28/30</p>
<p>168.172.128.116/30</p>
<p>168.172.128.124/30</p>
<p>168.172.129.0/24</p>
<p>168.172.130.0/23</p>
<p>168.172.132.0/23</p>
<p>168.172.134.0/24</p>
<p>168.172.137.0/24</p>
<p>168.172.139.0/24</p>
<p>168.172.140.0/24</p>
<p>168.172.142.0/24</p>
<p>168.172.145.0/24</p>
<p>168.172.147.0/24</p>
<p>168.172.150.0/24</p>
<p>168.172.153.0/24</p>
<p>168.172.154.0/23</p>
<p>168.172.161.0/24</p>
<p>168.172.162.0/23</p>
<p>168.172.164.0/22</p>
<p>168.172.168.0/24</p>
<p>168.172.170.0/24</p>
<p>168.172.174.0/23</p>
<p>168.172.176.0/20</p>
<p>168.172.193.0/24</p>
<p>168.172.194.0/23</p>
<p>168.172.196.0/22</p>
<p>168.172.200.0/21</p>
<p>168.172.208.0/22</p>
<p>168.172.212.0/24</p>
<p>168.172.218.0/24</p>
<p>168.172.224.0/23</p>
<p>168.172.230.0/23</p>
<p>168.172.232.0/21</p>
<p>168.172.240.0/23</p>
<p>168.172.243.0/24</p>
<p>168.172.252.0/24</p>
<p>168.210.50.0/24</p>
<p>192.33.10.0/24</p>
<p>192.42.99.0/24</p>
<p>192.94.61.0/24</p>
<p>192.94.210.0/24</p>
<p>192.96.2.0/24</p>
<p>192.96.5.0/24</p>
<p>192.96.7.0/24</p>
<p>192.96.8.0/24</p>
<p>192.96.15.0/24</p>
<p>192.96.20.0/23</p>
<p>192.96.109.0/24</p>
<p>192.96.140.0/23</p>
<p>192.96.142.0/24</p>
<p>192.96.247.0/24</p>
<p>192.96.248.0/23</p>
<p>192.96.250.0/24</p>
<p>192.96.252.0/24</p>
<p>192.102.9.0/24</p>
<p>196.4.81.0/24</p>
<p>196.4.95.0/24</p>
<p>196.6.198.0/24</p>
<p>196.6.221.0/24</p>
<p>196.10.121.0/24</p>
<p>196.10.203.0/24</p>
<p>196.11.76.0/22</p>
<p>196.11.98.0/24</p>
<p>196.11.136.0/21</p>
<p>196.11.235.0/24</p>
<p>196.13.115.0/24</p>
<p>196.13.116.0/22</p>
<p>196.13.150.0/24</p>
<p>196.13.184.0/23</p>
<p>196.13.187.0/24</p>
<p>196.13.188.0/23</p>
<p>196.13.190.0/24</p>
<p>196.13.200.0/22</p>
<p>196.13.204.0/24</p>
<p>196.21.0.0/23</p>
<p>196.21.8.0/24</p>
<p>196.21.15.0/27</p>
<p>196.21.15.32/28</p>
<p>196.21.15.80/28</p>
<p>196.21.15.96/27</p>
<p>196.21.15.128/29</p>
<p>196.21.31.0/24</p>
<p>196.21.32.0/20</p>
<p>196.21.48.0/21</p>
<p>196.21.56.0/22</p>
<p>196.21.60.0/23</p>
<p>196.21.63.0/24</p>
<p>196.21.64.0/21</p>
<p>196.21.72.0/22</p>
<p>196.21.76.0/23</p>
<p>196.21.79.48/28</p>
<p>196.21.80.0/21</p>
<p>196.21.89.0/24</p>
<p>196.21.91.0/24</p>
<p>196.21.92.0/22</p>
<p>196.21.96.64/26</p>
<p>196.21.97.0/24</p>
<p>196.21.98.0/23</p>
<p>196.21.104.0/24</p>
<p>196.21.108.0/22</p>
<p>196.21.112.0/20</p>
<p>196.21.132.0/22</p>
<p>196.21.136.0/22</p>
<p>196.21.141.0/24</p>
<p>196.21.142.0/24</p>
<p>196.21.144.0/21</p>
<p>196.21.153.0/24</p>
<p>196.21.157.0/24</p>
<p>196.21.158.0/23</p>
<p>196.21.160.0/22</p>
<p>196.21.168.0/21</p>
<p>196.21.176.0/20</p>
<p>196.21.192.0/21</p>
<p>196.21.200.0/24</p>
<p>196.21.206.0/24</p>
<p>196.21.209.0/24</p>
<p>196.21.210.0/24</p>
<p>196.21.218.0/24</p>
<p>196.21.224.0/22</p>
<p>196.21.228.0/23</p>
<p>196.21.230.0/24</p>
<p>196.21.232.0/22</p>
<p>196.21.236.0/23</p>
<p>196.21.240.0/23</p>
<p>196.21.242.0/24</p>
<p>196.21.244.0/22</p>
<p>196.21.248.0/23</p>
<p>196.21.251.0/24</p>
<p>196.21.252.0/23</p>
<p>196.24.4.0/24</p>
<p>196.24.6.0/23</p>
<p>196.24.16.0/20</p>
<p>196.24.32.0/23</p>
<p>196.24.36.0/22</p>
<p>196.24.40.0/22</p>
<p>196.24.44.0/24</p>
<p>196.24.45.0/27</p>
<p>196.24.45.64/27</p>
<p>196.24.46.0/24</p>
<p>196.24.47.0/25</p>
<p>196.24.47.128/27</p>
<p>196.24.48.0/22</p>
<p>196.24.52.0/24</p>
<p>196.24.55.0/24</p>
<p>196.24.56.0/23</p>
<p>196.24.58.0/24</p>
<p>196.24.62.0/24</p>
<p>196.24.69.0/24</p>
<p>196.24.70.0/23</p>
<p>196.24.72.0/23</p>
<p>196.24.74.0/24</p>
<p>196.24.76.0/22</p>
<p>196.24.80.0/24</p>
<p>196.24.83.0/24</p>
<p>196.24.85.0/24</p>
<p>196.24.86.0/23</p>
<p>196.24.88.0/24</p>
<p>196.24.90.0/23</p>
<p>196.24.93.0/24</p>
<p>196.24.94.0/24</p>
<p>196.24.96.0/23</p>
<p>196.24.99.0/24</p>
<p>196.24.101.0/24</p>
<p>196.24.102.0/23</p>
<p>196.24.105.0/24</p>
<p>196.24.110.0/28</p>
<p>196.24.112.0/23</p>
<p>196.24.116.0/22</p>
<p>196.24.216.0/23</p>
<p>196.24.218.0/24</p>
<p>196.24.220.0/22</p>
<p>196.24.224.0/20</p>
<p>196.24.240.0/22</p>
<p>196.24.244.0/28</p>
<p>196.24.245.0/24</p>
<p>196.24.246.0/24</p>
<p>196.24.249.0/28</p>
<p>196.24.250.0/24</p>
<p>196.37.24.0/22</p>
<p>196.37.151.0/24</p>
<p>196.248.0.0/16</p>
<p>196.249.0.0/18</p>
<p>196.249.128.0/18</p>
<p>196.252.0.0/16</p>
<p>196.253.128.0/17</p>
<p>197.7.83.0/24</p>
<p>198.54.58.0/24</p>
<p>198.54.65.0/24</p>
<p>198.54.66.0/24</p>
<p>198.54.68.0/24</p>
<p>198.54.83.0/24</p>
<p>198.54.91.0/24</p>
<p>198.54.155.0/24</p>
<p>198.54.173.0/24</p>
<p>198.54.174.0/24</p>
<p>198.54.223.0/24</p>
<p>198.54.234.0/24</p>
<p>198.54.249.0/24</p>
<p>198.54.250.0/23</p>
<p>199.7.83.0/24</p>
<p>210.98.27.101/32</p>
<p>210.98.27.105/32</p>
<p>210.98.51.10/32</p>
<p>210.98.54.10/32</p>
<p>210.219.33.10/32</p>
<p>210.219.33.23/32</p>
        </div>
        <div className="constituency-detail description">
          <strong>Description of Formal Constituency</strong>
          <p>Higher Education Institutions of Mozambique</p>
          <p>Public universities and science/research councils of South Africa including supporting institutions</p>
        </div>
      </div>

      <h3>Team Contact Information</h3>
      <div className="contact-infos">
        <div className="contact-details">
          <strong>Main Number</strong>
          <p>+27 21 763 7147 TENET 24x7 </p>
            <p>SOC</p>
          
        </div>
        <div className="contact-details">
          <strong>Emergency Number</strong>
          <p>+27 21 763 7147 TENET 24x7 </p>
          <p>SOC</p>
        </div>
        <div className="contact-details">
          <strong>Fax Number</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Email</strong>
          <p>csirt@sanren.ac.za</p>
        </div>
        <div className="contact-details">
          <strong>Other Contact</strong>
          <p>Fax on request</p>
        
        </div>
        <div className="contact-details">
          <strong>Postal Address</strong>
          <p>SANReN CSIRT
        <p>c/o Dr. Renier van Heerden</p>
        <p>CSIR</p>
        <p>PO Box 395</p>
         <p></p>Pretoria</p>
         <p>0001</p>
        </div>
        <div className="contact-details">
          <strong>Automated Reporting E-Mail</strong>
          <p>csirt@sanren.ac.za</p>
        </div>
        <div className="contact-details">
          <strong>Autom. Rep. Supported Formats</strong>
          <p>-</p>
        </div>
        <div className="contact-details">
          <strong>Business Hours</strong>
          <p>Weekdays 08:00 to 16:30 SAST</p>
          
        </div>
        <div className="contact-details">
          <strong>Timezone</strong>
          <p>Africa/Johannesburg</p>
        </div>
      </div>

      <h3>Cryptography</h3>
      <div className="cryptography">
      <p>Type: RSA/4096    Expires: 2024-04-28 -- EXPIRED!</p>
        <p>Fpr:  8E0A 2756 1B3E 2B99 4246 C1AA 04A1 B821 AA99 CA2C</p>
        <p>Sub: RRSA/4096  Usage: Encrypt  EXPIRED!: 2024-04-28</p>
        <p>UID: SANReN CSIRT 2023 &lt;csirt@sanren.ac.za&gt;</p>
        
</div>
        <div className="section">
        
        <h3>Memberships</h3>
      <div className="memberships-infos"></div>
        <div className="memberships-details">
          <strong>AfricaCERT / OIC-CERT</strong>
          <p>Full Member since </p> 
        </div>
        <div className="memberships-details">
          <strong>FIRST	</strong>
          <p>Full Member since 06 Feb 2020</p> 
        </div>
        <div className="memberships-details">
          <strong>TF-CSIRT / TI	</strong>
          <p>TI Listed since</p> 
        </div>

        <div className="section">
        <h3>Classification</h3>
        <div className="classifications-infos"></div>
        <div className="classification-details">
          <strong>Current State	</strong>
          <p>Listed since 28 Nov 2023</p>
          </div>

         <div className="classification-details">
          <strong> Entry Date	</strong>
          <p>17 Nov 2022</p>
          </div>

          <div className="section">

        <h3>History</h3>
        <div className="history-infos"></div>
        <div className="historys-details">
          <strong>Date	</strong>
          <p> 28 Nov 2023</p>
          </div>

         <div className="historys-details">
          <strong>Describtion</strong>
          <p>SA NREN CSIRT (ZA) is now a listed team</p>
          </div> 
        </div>
        </div>
        </div>
      </div>
  );
};

export default  SACsirt;
